import React, {Component} from 'react';
import DescriptionSection, {IDescriptionItem} from '../LandingPage/DescriptionSection';
import RegisterSection from '../LandingPage/RegisterSection';
import QuestionSection from '../LandingPage/QuestionSection';
import LayoutWrapper from '../LayoutWrapper';
import {IAccordionItem, Translation} from 'jobhunter-common-web';

const description: IDescriptionItem[] = [
    {
        title: 'candidate.descriptionSection.selection.title',
        subtitle: 'candidate.descriptionSection.selection.subtitle',
        description: 'candidate.descriptionSection.selection.description',
        icon: 'MessageSquare',
    },
    {
        title: 'candidate.descriptionSection.employee.title',
        subtitle: 'candidate.descriptionSection.employee.subtitle',
        description: 'candidate.descriptionSection.employee.description',
        icon: 'FileText',
    },
    {
        title: 'candidate.descriptionSection.relocation.title',
        subtitle: 'candidate.descriptionSection.relocation.subtitle',
        description: 'candidate.descriptionSection.relocation.description',
        icon: 'Award',
    },
];

const questionsData: typeof IAccordionItem[] = [
    {
        title: 'Do you help with legalization and relocation processes?',
        description: 'Yes we do. We have wide experience when it comes to legalization and relocation processes, both for candidate and his family. We co-operate with few Partners to cover different areas of those processes. Usually it is an employer who hires us to support you, but you can also find our Services on the marketplace.',
        icon: 'Layout',
    },
    {
        title: 'Do I have to change my location when hired with your platform?',
        description:
            'It depends on a certain offer. We do not require the relocation process being conducted with us. It is the employer who decides what kind of job he offers.',
        icon: 'Heart',
    },
    {
        title: 'How much do I pay for joining your platform?',
        description: 'It is free for candidates. We charge only employers who decide to work with us. So no worries about the costs!',
        icon: 'DollarSign',
    },
];

class Candidate extends Component {
    render() {
        return (
            <LayoutWrapper>
                <div className="page-layout">
                    <div className="banner candidate-banner">
                        <h1 className="title">
                            <span>
                                <Translation text="candidate.title" />
                            </span>
                            <span>
                                <Translation text="candidate.description" />
                            </span>
                        </h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="page-container">
                                <DescriptionSection
                                    title="candidate.descriptionSection.title"
                                    description="candidate.descriptionSection.description"
                                    descriptionItems={description}
                                />
                                <RegisterSection
                                    redirectLink={`${process.env.REACT_APP_CANDIDATE_URL}/auth/register`}
                                    title={'candidate.registrationSection.title'}
                                />
                                <QuestionSection questionsData={questionsData} />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default Candidate;
